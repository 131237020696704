.goal-item {
    &__top {
        display: flex;
        align-items: center;
        &__title {
            @extend .typo--l;
            color: $--color-white;
            margin-right: $--margin-s;
        }

        &__icon {
            border-radius: 50%;
            background-color: $--color-darker-white;
            @extend .flex-center;
            width: 4.5rem;
            height: 4.5rem;
            animation: pulse 2s linear infinite;
            &-icon {
                @extend .typo--2xl;
                color: $--color-primary;
            }
        }
    }

    &__bottom {
        @extend .typo--m;
        @extend .textWidth;
        line-height: 1.3;
        @extend .typo--light;
        margin-top: $--margin-s;
        color: $--color-white;
    }
}
