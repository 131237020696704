.ticketCard {
    background-color: #fff;
    margin-bottom: $--margin-s;
    padding: $--paddding-s;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
    &__header {
        @extend .typo--m;
        color: $--color-gray;
        font-weight: 400;
    }
    &__content {
        &__text {
            line-height: 1.4;
            font-weight: 400;
            margin-top: $--margin-s;
        }
        &__date {
            padding-block: $--paddding-s;
            color: $--color-gray;
            @extend .typo--s;
            &__text {
                margin-right: $--margin-xs1;
            }
        }
    }
    &__footer {
        &__button {
            margin-top: $--margin-s;
            display: block;
            width: fit-content;
            border-radius: $--paddding-s;
            background-color: $--color-primary;
            padding: 0.2rem $--paddding-s;
        }
    }
}
