.navbar {
  @extend .flex-between;
  background-color: $--color-black;
  min-height: 5rem;
  position: fixed;
  padding-inline: $--paddding-m;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 1px 2px 3px #444444;
  padding-block: $--margin-s;
  &-left {
    display: flex;
    align-items: center;
    &__logo {
      z-index: 101;
      width: 6rem;
      object-fit: contain;
    }
    &__menu {
      margin-right: $--margin-m;
      @include media-prop(display, flex, none, none);
      &__item {
        padding-inline: 1rem;
        &-link {
          color: $--color-white;
          @include media-prop(font-size, 1.9rem, 1.6rem, 1.6rem);
          white-space: nowrap;
          transition: $--transition;
          &.active {
            color: $--color-primary;
          }

          &:is(:hover, :active) {
            color: $--color-primary;
          }
        }
      }
    }
  }
  &-right {
    &__mobile-menu {
      @include media-prop(display, none, flex, flex);
      @extend .typo--2xl;
      color: $--color-white;
      cursor: pointer;
      transition: $--transition;
      &:is(:hover, :focus) {
        color: $--color-primary;
        transform: scale(1.1);
      }
    }
    &__social {
      @include media-prop(display, flex, none, none);
    }
  }
}
