.sidenav {
  position: fixed;
  height: 100vh;
  top: 0;
  right: -100%;
  background-color: $--color-black-light;
  transition: $--transition;
  z-index: 80;
  &-close {
    margin-right: auto;
    margin-top: 5rem;
    cursor: pointer;
  }
  @include media-prop(width, "nil", 50vw, 70vw);
  &.active {
    right: 0;
    display: block;
  }
  &-menu {
    margin-top: 10rem;
    &__item {
      padding-right: $--paddding-s;
      margin-bottom: $--margin-s;
      width: 100%;
      &:is(:active, :hover) {
        background-color: $--color-primary;
      }
      &:hover &-link {
        color: $--color-black-light;
      }
      &-link {
        padding-left: 2rem;
        color: $--color-white;
        @extend .typo--xl;
        @extend .typo--sbold;

        transition: $--transition;
      }
    }
  }
  &-social {
    margin-top: $--margin-m;
  }
}
