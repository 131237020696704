.social {
  display: flex;
  &-item {
    @extend .flex-center;
    width: 4rem;
    height: 4rem;
    @include media-prop(margin-right, $--margin-m, $--margin-s, $--margin-s);
    color: $--color-primary;

    border-radius: 50%;
    @extend .typo--xl;
    transition: $--transition;
    &:hover {
      color: $--color-white;
      transform: scale(1.1);
    }
  }
}
