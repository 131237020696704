.customModal {
    height: 0;
    overflow: hidden;

    &.active {
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: 0.3s ease-in-out;
        position: fixed;
        inset: 0;
        background-color: #000000cd;
        z-index: 1000;
    }
    &__header {
        position: relative;
        color: #fff;
        font-size: 3rem;
        left: -4rem;
        top: 5rem;
        width: fit-content;
        cursor: pointer;
        &:hover {
            color: goldenrod;
        }
    }
    &__children {
        position: relative;
        top: 50%;

        z-index: 10;
        width: 50%;
        margin: auto;
        transform: translateY(-50%);
    }
}
.modal-textbox {
    background-color: $--color-darker-white;
    padding: $--paddding-l;
    margin: auto;
    max-width: 80%;
    border-radius: $--paddding-xs1;
    &__text {
        @extend .typo--l;
        padding: $--paddding-m;
        text-align: center;
    }
}
