.support {
    @extend .container;
    min-height: 100vh;
    display: grid;
    @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
    place-items: center;
    overflow: hidden;
    margin-block: 8rem;
    &__right {
        &__img {
            @include media-prop(max-width, 40rem, 30rem, 20rem);
            border-radius: $--paddding-l;
            box-shadow: 1px 2px 3px $--color-white;
            object-fit: contain;
        }
    }
}
