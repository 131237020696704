.ourClients {
  text-align: center;
  padding-block: $--paddding-s;
  &-title {
    @extend .typo--xl;
    color: $--color-white;
    @extend .typo--sbold;
    line-height: 1.3;
    & span {
      @extend .gradient-text-primary;
      @extend .typo--bold;
    }
  }

  &-description {
    color: $--color-white;
    @extend .typo--l;
    @extend .typo--light;
    @include media-prop(width, 50%, 80%, 80%);
    margin: auto;
  }

  &__imgs {
    @extend .flex-center;
    gap: $--margin-s;
    @include media-prop(flex-direction, row, column, column);
    max-width: 10rem;
    margin: auto;
    &__item {
      object-fit: contain;
      padding: $--paddding-xs2;
      &:hover {
        filter: drop-shadow(5px 5px 10px #fff);
        opacity: 1;
      }
    }
  }
}
