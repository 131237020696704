.productPage {
  display: grid;
  @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
  @include media-prop(padding-inline, $--paddding-m, $--paddding-s, $--paddding-xs1);
  place-items: center;
  margin-block: 15rem;
  &__left {
    &__img {
      object-fit: contain;
      @include media-prop(max-width, 50rem, 25rem, 25rem);
    }
  }
  &__right {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__title {
      @extend .gradient-text-primary;
      @extend .typo--l;
      font-weight: bold;
      padding-bottom: $--paddding-xs1;
      border-bottom: 2px solid $--color-dark-white;
      width: fit-content;
      margin-bottom: $--margin-s;
    }
    &__price {
      color: $--color-white;
      @extend .typo--m;
      font-weight: bold;
    }
    &__rating {
      display: flex;
      align-items: center;
      margin-bottom: $--margin-s;
      &-start {
        &-item {
          color: goldenrod;
        }
      }
      &-text {
        color: $--color-darker-white;
        @extend .typo--s;
        margin-right: $--margin-s;
      }
    }
    &__description {
      color: $--color-white;
      @extend .typo--m;
      max-width: 60rem;
      line-height: 1.6;
      font-weight: 400;
      word-break: normal;
      margin-bottom: $--margin-m;
    }

    &__action {
      margin-bottom: $--margin-m;
    }
  }
}
