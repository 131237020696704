.contact {
  @include media-prop(padding-inline, 11.2rem, 4.2rem, 0);
  overflow: hidden;
  margin-block: $--margin-l;
  &-title {
    text-align: center;
    @extend .typo--2xl;
    @extend .typo--bold;
    @extend.gradient-text-primary;
    margin-bottom: $--margin-m;
  }
  &-form {
    display: flex;
    justify-content: center;
    @include media-prop(flex-direction, row, column-reverse, column-reverse);

    &-left {
      @include media-prop(width, 50%, 100%, 100%);
      position: relative;
      padding: $--paddding-l;
      @include media-prop(background-color, $--color-black-light, transparent, transparent);

      &__top {
        &__title {
          @extend .typo--xl;
          @extend .typo--sbold;
          color: $--color-white;
        }
        &__description {
          @extend .typo--l;
          @extend .typo--regular;
          color: $--color-white;
          margin-bottom: $--margin-m;
        }
      }

      &__bottom {
        &__title {
          @extend .typo--m;
          margin-block: $--margin-s;
          @extend.gradient-text-primary;
        }
      }
    }

    &-right {
      @include media-prop(width, 50%, 100%, 100%);
      background-color: $--color-darker-white;
    }
  }
}
