.custom-input {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: $--margin-m;
    }
    &__label {
        font-size: 1.6rem;
        color: $--color-gray;
        width: max-content;
        &.light {
            color: $--color-darker-white;
        }
        &-required {
            color: red;
        }
    }
    &__input,
    &__textarea {
        outline: transparent;
        border: 1px solid transparent;
        padding: $--paddding-s $--paddding-m;
        border-radius: $--paddding-xs1;
        width: 100%;
        :is(:focus) {
            border-color: $--color-gray;
        }
    }
    &__textarea {
        @include media-prop(height, 30vh, 10vh, 10vh);
        resize: none;
    }
    input[type="checkbox"] {
        width: max-content;
    }
}
