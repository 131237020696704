.product-card {
  background-color: #fff;
  box-shadow: $--box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: $--paddding-s;
  transition: $--transition;
  &:hover {
    box-shadow: $--box-shadow;
    -webkit-box-shadow: $--webkit-box-shadow;
  }
  &__coupon {
    padding: $--paddding-m;
    background-color: purple;
    color: $--color-white;
    position: relative;
    width: fit-content;
    margin-right: $--margin-s;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #fff;
    }
    &::before {
      left: -1rem;
    }
    &::after {
      right: -1rem;
    }
    &__text {
      font-weight: bold;
      @extend .typo--l;
      display: block;
    }
  }

  &__top {
    width: 100%;
    padding-block: $--paddding-s;
    &-img {
      object-fit: cover;
      width: 25rem;
      margin: auto;
    }
    &-title {
      margin-top: $--margin-s;
      @extend .typo--l;
      line-height: 1.2;
      font-weight: bold;
      text-align: start;
      padding-inline: $--paddding-s;
    }
  }
  &__body {
    padding-inline: $--paddding-s;
    margin-bottom: $--margin-s;
    &-title {
      text-align: right;
      @extend .typo--s;
      margin-bottom: $--margin-s;
    }
    &-description {
      font-weight: 500;
      @extend .typo--s;
      text-align: start;
      line-height: 1.1;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__footer {
    padding-block: $--paddding-s;
    &__categories {
      display: flex;
      align-items: center;
      &__item {
        max-width: fit-content;
        padding: 0.5rem 2rem;
        background-color: $--color-white;
        border-radius: 2rem;
        margin-right: $--margin-s;
        &:first-child {
          background-color: $--color-primary;
          @extend .btn;
          display: block;
        }

        &-link {
          color: $--color-black;
          @extend .typo--m;
        }
        &-text {
          @extend .typo--xs;
          color: $--color-black;
        }
      }
    }
  }
  &__adminActions {
    width: 100%;
    margin-top: $--margin-s;
    & .btn {
      margin-left: 20px;
    }
  }
}
