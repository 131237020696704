.single {
  width: 100%;
  &__container {
    &__top {
      padding: $--paddding-m;
      display: grid;
      // @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
      grid-template-columns: 1fr;
      gap: $--margin-m;

      &__left {
        background-color: $--color-darker-white;
        padding: $--paddding-m;
        box-shadow: $--box-shadow;
        -webkit-box-shadow: $--webkit-box-shadow;
        position: relative;
        &__editbtn {
          position: absolute;
          top: 0;
          right: 0;
          padding: $--paddding-s;
          font-size: 1.2rem;
          color: #7451f8;
          background-color: #7551f833;
          cursor: pointer;
          border-radius: 0 0 0 5px;
        }
        &__title {
          text-align: center;
          color: $--color-gray;
          font-weight: 300;
          font-size: 1.2rem;
        }
        &__item {
          display: flex;
          gap: $--margin-m;
          &__details {
            &__title {
              margin: $--margin-s;
              color: $--color-gray;
            }
            &__item {
              margin-bottom: $--margin-s;
              font-size: 1.4rem;
              &-key {
                font-weight: bold;
                color: $--color-gray;
                padding-left: $--paddding-xs1;
              }
              &-value {
                font-weight: 300;
              }
            }
          }
          &__img {
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
      &__right {
        padding: $--paddding-m;
        box-shadow: $--box-shadow;
        -webkit-box-shadow: $--webkit-box-shadow;
      }
    }
    &__bottom {
      padding: $--paddding-m;

      box-shadow: $--box-shadow;
      -webkit-box-shadow: $--webkit-box-shadow;
    }
  }
}
