.login {
    overflow: hidden;
    @extend .container;
    padding-block: 8rem;
    &__title {
        text-align: center;
        @extend .typo--2xl;
        @extend .gradient-text-primary;
        @extend .typo--bold;
        margin-top: $--margin-2xl;
        width: max-content;
    }
    &__content {
        display: grid;
        @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
        place-items: center;
        @include media-prop(gap, $--margin-l, $--margin-s, $--margin-s);
        height: 80vh;
    }
    &__right {
        @include media-prop(max-width, 100%, 20rem, 20rem);
        &__img {
            object-fit: contain;
            transition: $--transition;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
    &__left {
        &__form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__inputcontainer{
                display: flex;
                align-items: center;
                background-color: $--color-darker-white;
                
            }
            &__item {
                margin-bottom: $--margin-m;

                &__input {
                    background-color: transparent;
                    border: 1px solid transparent;
                    outline: transparent;
                    padding: $--paddding-s $--paddding-m;
                    &::placeholder {
                        color: $--color-gray;
                    }
                }
                &__icon {
                    @extend .typo--l;
                    padding-right: $--paddding-s;
                    color: $--color-gray;
                    display: block;
                    max-width: 2rem;
                 
                }
            }
        }
    }
}
