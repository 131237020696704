*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: $--font-family;
  direction: rtl;
}

body {
  background: $--dark-gradient;
}

img,
svg,
video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  @include media-prop(padding-inline, 7rem, 4.2rem, 1.6rem);
}

.textWidth {
  @include media-prop(width, 70%, 100%, 100%);
}

.section-top-margin {
  @include media-prop(margin-top, 6rem, 4.2rem, 1.6rem);
}

.gradient-text-primary {
  background: -webkit-linear-gradient($--color-primary, $--color-secondary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text-secondary {
  background: -webkit-linear-gradient(#fff, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $--color-darker-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $--color-primary;
  border-radius: 0.3rem;
  &:hover {
    background: $--color-secondary;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.pagesLayot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.warrning {
  background-color: #ffcc00;
  color: white;
  @extend .typo--l;
  text-align: center;
  padding: $--paddding-xs1;
  border-radius: $--paddding-xs1;

}
