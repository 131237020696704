.sidebtn {
    z-index: 100;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    @extend .flex-center;
    border: none;
    outline: none;
    cursor: pointer;
    position: fixed;
    &.active {
        right: 2rem;
    }
    &__icon {
        font-size: 1.8rem;
        color: $--color-black;
    }
}

.scroll-top {
    background-color: $--color-primary;
    bottom: 4rem;
    right: -20rem;
}

.support-btn {
    @extend .sidebtn;
    background-color: $--color-darker-white;
    bottom: 50%;
    transform: translateY(-50%);
    right: -20rem;
    animation: pulse 1s infinite linear;

    &::before,
    &::after {
        position: absolute;
        overflow: hidden;
        visibility: hidden;
        height: 0;
        width: 0;
    }
    &::before {
        content: "الدعم";
        width: max-content;
        color: $--color-black;
        background-color: $--color-darker-white;
        padding: $--paddding-xs1;
        left: -5rem;
    }
    &::after {
        content: "";
        position: absolute;
        left: -2rem;
        border: 1rem solid transparent;
        border-left-color: $--color-darker-white;
        height: 1rem;
        width: 1rem;
    }
    &:hover {
        &::before,
        &::after {
            opacity: 1;
            height: auto;
            width: auto;
            visibility: visible;
        }
    }
}
