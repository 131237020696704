.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: $--paddding-s;
  color: $--color-white;
  box-shadow: $--box-shadow;
  -webkit-box-shadow: $--webkit-box-shadow;

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__left {
    &__title {
      font-weight: bold;
      font-size: 1.3rem;
      color: rgb(160, 153, 153);
    }
    &__counter {
      font-size: 2.8rem;
      font-weight: 300;
    }
    &__link {
      font-size: 1.2rem;
      color: $--color-primary;
      border-bottom: 1px solid $--color-gray;
      width: max-content;
    }
  }
  &__right {
    display: flex;

    &__percentage {
      display: flex;
      align-items: center;
      @extend .typo--s;
      &.postive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }
    &__icon {
      & svg {
        font-size: 2rem;
      }
      align-self: flex-end;
      border-radius: 1rem;
    }
  }
}
.widget-container {
  display: grid;
  @include media-prop(grid-template-columns, repeat(4, 1fr), 1fr, 1fr);
  gap: $--margin-s;
}
