.form-wrapper {
    &__title {
        @extend .typo--xl;
        @extend .gradient-text-primary;
        text-align: center;
        margin-block: $--margin-l;
    }
    &__children {
        display: grid;
        gap: 1rem 2rem;
        justify-content: "flex-start";
        @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
        &.column {
            grid-template-columns: 1fr;
        }
    }
}
