.cform {
  padding: $--paddding-l;
  display: flex;
  flex-direction: column;

  & .btn {
    @include media-prop(width, 50%, 90%, 90%);
    margin: auto;
  }
}
