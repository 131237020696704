.admin-sidebar {
    background-color: $--sidenav-bg;
    height: 100vh;
    padding-top: $--paddding-m;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    @include media-prop(width, auto, 5rem, 5rem);
    border-left: 1px solid $--color-gray;
    &__center {
        &__logo {
            display: flex;
            justify-content: center;
            max-width: 7rem;
            margin: $--margin-s auto;
            &-img {
                object-fit: contain;
            }
        }
        &__menu {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}
