.multi-form {
    --border-radius: 1rem;

    border-radius: 1rem;
    padding: $--paddding-m;
    background-color: $--color-black;
    &__header {
        color: $--color-primary;
        @extend .typo--l;
        padding-top: 7rem;
    }
    &__footer {
        margin-top: $--margin-m;
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &-btn {
                margin-left: $--margin-m;
                &:disabled {
                    opacity: 0.4;
                }
            }
        }
        &__submit {
            margin-top: $--margin-m;
            background-color: $--color-white;
        }
    }
}
