.skillton {
    background-color: #ddd;
    margin: 1rem 0;
    border-radius: 0.3rem;
    animation: flash 1 infinite ease-in-out;
    &.text {
        width: 100%;
        height: 1rem;
    }
    &.circle {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
    }
    &.title {
        width: 50%;
        height: 2rem;
        margin-bottom: 1.5rem;
    }
    &.avatar {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }
    &.thumbnail {
        width: 10rem;
        height: 10rem;
    }
    &.box {
        width: 100%;
        height: 100%;
    }
    .card {
        width: 30rem;
        height: 30rem;
        border-radius: 1rem;
    }
}

@keyframes flash {
    0% {
        opacity: 0.2;
    }
    25% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.6;
    }
    75% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
