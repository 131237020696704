.chat-footer {
    background-color: $--color-darker-white;
    width: 100%;
    height: 100%;
    @extend .flex-between;
    padding-inline: $--paddding-s;
    &__input {
        width: 100%;
        height: 4rem;
        outline: none;
        border: 1px solid transparent;
        border-radius: $--paddding-xs1;
        padding-inline: $--paddding-m;
        &:focus {
            border-color: $--color-gray;
        }
    }
    &__icon {
        cursor: pointer;
        padding: $--paddding-s;
        @extend .flex-center;
        margin-right: $--margin-s;
        background-color: $--color-primary;
        border-radius: 50%;
        transform: scale(1.1);
        transition: $--transition;
        &:hover {
            transform: scale(1);
            box-shadow: $--box-shadow;
            -webkit-box-shadow: $--webkit-box-shadow;
        }
        &-icon {
            @extend .typo--l;
            color: $--color-darker-white;
        }
    }
}
