.value {
  display: flex;
  align-items: center;
  @extend .container;
  @include media-prop(flex-wrap, "nil", wrap, wrap);
  @include media-prop(justify-content, space-between, center, center);
  @include media-prop(padding-block, 4.2rem, 3.2rem, 1.6rem);
  &-right {
    &__title {
      @extend .typo--2xl;
      @extend .typo--sbold;
      color: $--color-black-light;
    }
    &__description {
      @include media-prop(font-size, 1.8rem, 1.8rem, 1.6rem);
      @include media-prop(max-width, 70%, 80%, 100%);
      @extend .typo--light;
      color: $--color-black-light;
      line-height: 1.7;
      margin-bottom: $--margin-m;
    }
    &__actions {
      & .btn {
        margin-right: $--margin-m;
      }
    }
  }
  &-left {
    @include media-prop(margin-top, "nill", $--margin-m, $--margin-m);
    &__img {
      @include media-prop(width, 40rem, 30rem, 17rem);
      & img {
        object-fit: contain;
      }
    }
  }
}
