.ticket-dorpdown {
    width: 100%;
    padding-top: $--paddding-s;
    &__header {
        @extend .flex-between;
        border-radius: $--paddding-xs1;
        background-color: #d0d0d0;
        padding: $--paddding-s $--paddding-m;
        &__right {
            display: flex;
            align-items: center;
            &__icon {
                @extend .typo--l;
                color: $--color-gray;
                transition: $--transition;
                cursor: pointer;
                &:hover {
                    color: $--color-black;
                }
            }
            &__title {
                margin-right: $--paddding-s;
                color: $--color-white;
                @extend .typo--xs;
                padding-inline: $--paddding-m;
                border-radius: $--paddding-m;
                &.open {
                    background-color: #198754;
                }
                &.urgent {
                    background-color: #d0342c;
                }
                &.hold {
                    background-color: #f29339;
                }
            }
        }
        &__count {
            @extend .ticket-dorpdown__header__right__icon;
            background-color: $--color-darker-white;
            padding: 0.1rem $--paddding-s;
            border-radius: $--paddding-s;
        }
    }
    &__children {
        display: none;
        max-height: 50rem;
        overflow-y: auto;
        margin-top: $--margin-s;
        &.active {
            display: block;
        }
    }
}
