table {
  border-collapse: collapse;
  margin: auto;
  width: 100%;
  background-color: #fff;
}
tr:nth-child(odd) {
  background: $--color-dark-white;
}
thead > tr:first-child {
  background-color: $--color-primary;
}
th {
  white-space: nowrap;
  width: fit-content;
  padding-inline: 2px;
  font-size: 16px;
}
td {
  &:first-child {
    padding-top: $--paddding-s;
  }
  // &:last-child {
  //   padding-bottom: $--paddding-s;
  // }
}
th,
td {
  border: 1px solid $--color-gray;
  padding-inline: 5px;
  text-align: center;
  font-size: 16px;
  max-width: 350px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tabel-container {
  max-width: 100%;
  max-height: 40rem;
  overflow: auto;
}

.tabel-footer {
  background-color: $--color-darker-white;
  width: 100%;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__page {
    @extend .typo--s;
  }
  &__actions {
    display: flex;
    align-items: center;

    font-size: 18px;

    &__btn {
      &:last-child {
        margin-right: 10px;
      }
      & svg {
        color: black;
        &:hover {
          color: $--color-primary;
        }
      }
    }
  }
}
