.admin-layout {
  width: 100%;

  &__top {
    flex: 1;
  }
  &__content {
    display: flex;

    &__left {
      background-color: $--dark-gradient;
      padding-top: 7rem;
      padding-inline: $--paddding-m;
      max-height: 100vh;
      overflow-y: auto;
      flex: 3;
      padding-bottom: $--paddding-m;
    }
  }
}
