.contact-item {
    margin-bottom: $--margin-m;
    opacity: 0.8;
    transition: $--transition;
    margin-bottom: $--margin-s;
    @include media-prop(width, max-content, auto, auto);
    display: block;
    &:hover {
        opacity: 1;
    }

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: $--margin-s;
        &.column {
            flex-direction: column;
        }
        &__icon {
            color: $--color-primary;
            @extend .typo--xl;
        }
        &__title {
            color: $--color-white;
            @extend .typo--l;
            @extend .typo--sbold;
            margin-right: $--margin-s;
            &.column {
                margin-right: 0;
            }
            &.dark {
                color: $--color-black;
            }
        }
    }
    &__bottom {
        text-align: start;
        &__desc {
            @extend .typo--s;
            color: $--color-white;
            &.dark {
                color: $--color-black;
                text-align: center;
            }
        }
    }
}
