.chatbubble {
    position: relative;
    float: right;
    width: 80%;
    right: 0;
    background-color: #65c466;
    padding: $--paddding-m;
    border-radius: $--paddding-s;
    margin: $--margin-s $--margin-xs1;
    word-wrap: break-word;

    &.send {
        left: 2rem;
        background-color: #367bf5;
        align-self: flex-end;
        float: left;
        text-align: left;
    }
    &__text {
        @extend .typo--l;
        color: $--color-white;
        line-height: 1.2;
        margin-bottom: $--margin-s;
        text-align: start;
    }
    &__timestamp {
        color: $--color-white;
        display: block;
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
        font-size: 0.8rem;
    }
    &__username {
        color: $--color-dark-white;
        @extend .typo--m;
    }
    &__icon {
        position: absolute;
        top: 1rem;
        left: 1rem;
        color: $--color-white;
        &.seen {
            background-color: transparent;
            color: $--color-darker-white;
        }
    }
}

.ticketTitle {
    width: max-content;
    max-width: 90%;
    text-align: center;
    margin: $--margin-s auto;
    &-text {
        @extend .typo--m;
        border-radius: $--paddding-s;
        background-color: $--color-darker-white;
        padding: $--paddding-m;
    }
}
