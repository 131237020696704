.chart {
  color: $--color-white;
  flex: 5;
  @extend .typo--m;
  padding: $--paddding-s;
  &__title {
    margin-bottom: $--margin-m;
    font-size: 1.4rem;
    font-weight: 300;
  }
  & .chartGrid {
    stroke: rgba(128, 128, 128, 0.372);
  }
}
